import * as React from "react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { WizardHotelPWA } from "../WizardHotelPWA";
import { WizardHotelPWAProps } from "../typings";

export const WizardHotelPWAHotelscom = (props: WizardHotelPWAProps) => {
  const component = (
    <EGDSLayoutFlex className="hotelscom" alignItems="center" justifyContent="center" blockSize="full_size">
      <EGDSLayoutFlexItem>
        <EGDSCard overflow>
          <EGDSSpacing padding={{ blockstart: "three", inline: "three" }}>
            <div>
              <WizardHotelPWA {...props} />
            </div>
          </EGDSSpacing>
        </EGDSCard>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );

  return (
    <Viewport>
      <ViewSmall>{component}</ViewSmall>
      <ViewMedium>
        <EGDSSpacing margin={{ inline: "three" }}>{component}</EGDSSpacing>
      </ViewMedium>
    </Viewport>
  );
};

export default WizardHotelPWAHotelscom;

export const cruisesLineAu = [
  {
    text: "All Cruise Lines",
    value: "-99",
  },
  {
    text: "APT Luxury Travel",
    value: "139",
  },
  {
    text: "Aurora Expeditions",
    value: "272",
  },
  {
    text: "Avalon Waterways au",
    value: "84",
  },
  {
    text: "Azamara Club Cruises",
    value: "116",
  },
  {
    text: "Captain Cook Cruises",
    value: "38",
  },
  {
    text: "Carnival Cruises",
    value: "1",
  },
  {
    text: "Carnival Cruises au",
    value: "164",
  },
  {
    text: "Celebrity Cruises",
    value: "6",
  },
  {
    text: "Coral Expeditions",
    value: "299",
  },
  {
    text: "Cunard Line",
    value: "16",
  },
  {
    text: "Disney Cruises",
    value: "9",
  },
  {
    text: "Emerald Cruises au",
    value: "229",
  },
  {
    text: "Holland America Line",
    value: "3",
  },
  {
    text: "MSC Cruises",
    value: "46",
  },
  {
    text: "Norwegian Cruise Line",
    value: "8",
  },
  {
    text: "Oceania Cruises",
    value: "58",
  },
  {
    text: "P&O Cruises Australia",
    value: "104",
  },
  {
    text: "Ponant au",
    value: "214",
  },
  {
    text: "Princess Cruises",
    value: "2",
  },
  {
    text: "Quark Expeditions au",
    value: "290",
  },
  {
    text: "Regent Seven Seas Cruises",
    value: "15",
  },
  {
    text: "Royal Caribbean Cruise Line",
    value: "4",
  },
  {
    text: "Scenic Cruises au",
    value: "228",
  },
  {
    text: "Seabourn Cruises",
    value: "21",
  },
  {
    text: "Silversea Cruises",
    value: "17",
  },
  {
    text: "Travelmarvel",
    value: "200",
  },
  {
    text: "Uniworld River Cruises au",
    value: "136",
  },
  {
    text: "Windstar Cruises",
    value: "24",
  },
];

export const dateRangesDe = [
  {
    monthKey: "januaryEscapeSentence",
    month: 1,
    years: {
      2021: 14897,
      2022: 15024,
    },
  },
  {
    monthKey: "februaryEscapeSentence",
    month: 2,
    years: {
      2021: 14898,
      2022: 15025,
    },
  },
  {
    monthKey: "marchEscapeSentence",
    month: 3,
    years: {
      2021: 14899,
      2022: 15026,
    },
  },
  {
    monthKey: "aprilEscapeSentence",
    month: 4,
    years: {
      2021: 14900,
      2022: 15027,
    },
  },
  {
    monthKey: "mayEscapeSentence",
    month: 5,
    years: {
      2021: 14990,
      2022: 15636,
    },
  },
  {
    monthKey: "juneEscapeSentence",
    month: 6,
    years: {
      2021: 15011,
      2022: 15637,
    },
  },
  {
    monthKey: "julyEscapeSentence",
    month: 7,
    years: {
      2021: 15012,
      2022: 15638,
    },
  },
  {
    monthKey: "augustEscapeSentence",
    month: 8,
    years: {
      2021: 15013,
      2022: 15639,
    },
  },
  {
    monthKey: "septemberEscapeSentence",
    month: 9,
    years: {
      2021: 15014,
      2022: 15640,
    },
  },
  {
    monthKey: "octoberEscapeSentence",
    month: 10,
    years: {
      2020: 14894,
      2021: 15015,
      2022: 15641,
    },
  },
  {
    monthKey: "novemberEscapeSentence",
    month: 11,
    years: {
      2020: 14895,
      2021: 15016,
      2022: 15642,
    },
  },
  {
    monthKey: "decemberEscapeSentence",
    month: 12,
    years: {
      2020: 14896,
      2021: 15017,
      2022: 15643,
    },
  },
];

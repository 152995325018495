export const destinationsDe = [
  {
    text: "Afrika",
    value: "76",
  },
  {
    text: "Alaska",
    value: "75",
  },
  {
    text: "Amazonas",
    value: "114",
  },
  {
    text: "Antarktis",
    value: "77",
  },
  {
    text: "Arktis",
    value: "171",
  },
  {
    text: "Asien",
    value: "173",
  },
  {
    text: "Australien",
    value: "61",
  },
  {
    text: "Britische Inseln",
    value: "70",
  },
  {
    text: "Galápagos",
    value: "99",
  },
  {
    text: "Grönland",
    value: "103",
  },
  {
    text: "Große Seen",
    value: "93",
  },
  {
    text: "Hawaii",
    value: "59",
  },
  {
    text: "Indischer Ozean",
    value: "84",
  },
  {
    text: "Island",
    value: "151",
  },
  {
    text: "Kaledonischer Kanal",
    value: "152",
  },
  {
    text: "Kanarische Inseln",
    value: "68",
  },
  {
    text: "Karibik",
    value: "53",
  },
  {
    text: "Mexikanische Riviera",
    value: "74",
  },
  {
    text: "Mittelamerika",
    value: "155",
  },
  {
    text: "Mittelmeer",
    value: "64",
  },
  {
    text: "Neuengland",
    value: "73",
  },
  {
    text: "Neuseeland",
    value: "62",
  },
  {
    text: "Nordamerika",
    value: "274",
  },
  {
    text: "Nordland",
    value: "69",
  },
  {
    text: "Nord-Ostsee-Kanal",
    value: "154",
  },
  {
    text: "Nord-Pazifik",
    value: "131",
  },
  {
    text: "Orient & Emirate",
    value: "187",
  },
  {
    text: "Ostasien",
    value: "135",
  },
  {
    text: "Östliche Karibik",
    value: "54",
  },
  {
    text: "Östliches Mittelmeer",
    value: "65",
  },
  {
    text: "Ostsee",
    value: "71",
  },
  {
    text: "Panama-Kanal",
    value: "57",
  },
  {
    text: "Rotes Meer",
    value: "85",
  },
  {
    text: "Schwarzes Meer",
    value: "67",
  },
  {
    text: "Südamerika",
    value: "58",
  },
  {
    text: "Südliche Karibik",
    value: "56",
  },
  {
    text: "Südostasien",
    value: "63",
  },
  {
    text: "Süd-Pazifik",
    value: "162",
  },
  {
    text: "Südsee",
    value: "60",
  },
  {
    text: "Suez-Kanal-Passage",
    value: "97",
  },
  {
    text: "Transatlantik",
    value: "72",
  },
  {
    text: "USA Ostküste",
    value: "94",
  },
  {
    text: "USA Westküste",
    value: "98",
  },
  {
    text: "Weltreise",
    value: "92",
  },
  {
    text: "West-Europa",
    value: "87",
  },
  {
    text: "Westl. Mittelmeer",
    value: "66",
  },
  {
    text: "Westliche Karibik",
    value: "55",
  },
  {
    text: "Amazonas",
    value: "120",
  },
  {
    text: "Amur",
    value: "174",
  },
  {
    text: "Ayeyarwady",
    value: "159",
  },
  {
    text: "Benelux",
    value: "147",
  },
  {
    text: "Brahmaputra",
    value: "140",
  },
  {
    text: "Chindwin",
    value: "158",
  },
  {
    text: "Dnjepr",
    value: "111",
  },
  {
    text: "Donau",
    value: "104",
  },
  {
    text: "Donaudelta",
    value: "182",
  },
  {
    text: "Douro",
    value: "127",
  },
  {
    text: "Elbe",
    value: "110",
  },
  {
    text: "Frankreich",
    value: "145",
  },
  {
    text: "Ganges",
    value: "141",
  },
  {
    text: "Göta-Kanal",
    value: "163",
  },
  {
    text: "Guadalquivir",
    value: "138",
  },
  {
    text: "Guadiana",
    value: "156",
  },
  {
    text: "GUS",
    value: "148",
  },
  {
    text: "Havel",
    value: "118",
  },
  {
    text: "Ijssel",
    value: "130",
  },
  {
    text: "Irrawaddy",
    value: "177",
  },
  {
    text: "Kwai",
    value: "166",
  },
  {
    text: "Lena",
    value: "164",
  },
  {
    text: "Maas",
    value: "124",
  },
  {
    text: "Main",
    value: "106",
  },
  {
    text: "Main-Donau-Kanal",
    value: "107",
  },
  {
    text: "Masurische Seen",
    value: "139",
  },
  {
    text: "Mekong",
    value: "142",
  },
  {
    text: "Mississippi",
    value: "86",
  },
  {
    text: "Moldau",
    value: "117",
  },
  {
    text: "Mosel",
    value: "101",
  },
  {
    text: "Moskwa",
    value: "115",
  },
  {
    text: "Neckar",
    value: "108",
  },
  {
    text: "Newa",
    value: "116",
  },
  {
    text: "Nil",
    value: "126",
  },
  {
    text: "Oder",
    value: "119",
  },
  {
    text: "Oder-Havel-Kanal",
    value: "122",
  },
  {
    text: "Ostseeküste",
    value: "132",
  },
  {
    text: "Peene",
    value: "143",
  },
  {
    text: "Polen",
    value: "150",
  },
  {
    text: "Rhein",
    value: "105",
  },
  {
    text: "Rhône",
    value: "100",
  },
  {
    text: "Saar",
    value: "136",
  },
  {
    text: "Saône",
    value: "113",
  },
  {
    text: "Schelde",
    value: "125",
  },
  {
    text: "Seine",
    value: "109",
  },
  {
    text: "Süd-Europa",
    value: "146",
  },
  {
    text: "Südostasien",
    value: "149",
  },
  {
    text: "Waal",
    value: "128",
  },
  {
    text: "Weichsel",
    value: "133",
  },
  {
    text: "Wolga",
    value: "102",
  },
  {
    text: "Yangtse",
    value: "112",
  },
  {
    text: "Expeditionen",
    value: "78",
  },
  {
    text: "Golf-Reisen",
    value: "167",
  },
  {
    text: "Hurtigruten",
    value: "179",
  },
  {
    text: "Rad-Kreuzfahrten",
    value: "183",
  },
  {
    text: "Schnupperkreuzfahrt",
    value: "172",
  },
  {
    text: "Segelkreuzfahrt",
    value: "91",
  },
  {
    text: "Silvesterkreuzfahrt",
    value: "96",
  },
  {
    text: "Themenkreuzfahrt",
    value: "83",
  },
  {
    text: "Weihnachtskreuzfahrt",
    value: "95",
  },
];

export const durationsAu = [
  {
    text: "Any duration",
    value: "-99",
  },
  {
    text: "2 - 6 nights",
    value: "1",
  },
  {
    text: "7 - 9 nights",
    value: "2",
  },
  {
    text: "10 - 14 nights",
    value: "3",
  },
  {
    text: "Over 14 nights",
    value: "4",
  },
];

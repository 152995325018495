import * as React from "react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { LocalizedComponentClass, LocalizedComponentProps, withLocalization } from "bernie-l10n";
import { EGDSIcon } from "@egds/react-core/icons";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { useLocalization } from "@shared-ui/localization-context";

import { WizardHotelPWA } from "../WizardHotelPWA";
import { WizardHotelPWAProps } from "../typings";
import { sendDelayedTrackEvent, Action, FlexTrackingInfo } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { EGDSTertiaryButton } from "@egds/react-core/button";
import { withStores } from "stores";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSText } from "@egds/react-core/text";

const getTrackingConfig = (isExpanded: boolean): FlexTrackingInfo => {
  const key = isExpanded ? "open" : "close";

  return {
    moduleName: "wizard-hotel-pwa",
    action: Action.CLICK,
    linkName: `${key} wizard with expando`,
    rfrr: `expando.${key}`,
  };
};

const useExpando = (defaultState: boolean, analytics?: AnalyticsStore) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultState);

  const toggle = React.useCallback(
    () =>
      setIsExpanded((isExp) => {
        const nextState = !isExp;
        const trackInfo = getTrackingConfig(nextState);

        sendDelayedTrackEvent(trackInfo, analytics);

        return nextState;
      }),
    [setIsExpanded, analytics]
  );

  return {
    toggle,
    isExpanded,
  };
};

export const WizardHotelPWAExpando = withStores(
  "analytics",
  "context",
  "cookies",
  "compositionStore",
  "wizardState"
)((props: WizardHotelPWAProps) => {
  const { toggle, isExpanded } = useExpando(false, props.analytics);

  return (
    <Viewport>
      <ViewSmall>
        <div data-stid="section-hotel-search" id={props.templateComponent.metadata.id}>
          {isExpanded ? <OpenedWizard {...props} handleClose={toggle} /> : <ExpandoWizard handleClick={toggle} />}
        </div>
      </ViewSmall>
      <ViewMedium>
        <EGDSSpacing padding={{ inline: "three" }}>
          <WizardHotelPWA {...props} additionalClasses="elevation" />
        </EGDSSpacing>
      </ViewMedium>
    </Viewport>
  );
});

interface OpenedWizardProps extends LocalizedComponentProps, WizardHotelPWAProps {
  handleClose: () => void;
}

const OpenedWizard: React.FC<OpenedWizardProps> = (props) => {
  const { formatText } = useLocalization();

  return (
    <>
      <WizardHotelPWA {...props} />
      <EGDSSpacing padding={{ blockend: "six" }}>
        <div>
          <EGDSTertiaryButton onClick={props.handleClose} isFullWidth>
            {formatText("DestinationsByAffinity.priceDialogCloseButton")}
          </EGDSTertiaryButton>
        </div>
      </EGDSSpacing>
    </>
  );
};

interface ExpandoWizardProps extends LocalizedComponentProps {
  handleClick: () => void;
}

const ExpandoWizard = withLocalization(({ l10n, ...props }: ExpandoWizardProps) => {
  const { formatText } = useLocalization();

  return (
    <EGDSSpacing padding="three">
      <EGDSLink onClick={props.handleClick}>
        <div>
          <EGDSLayoutFlex alignItems="center" justifyContent="start">
            <EGDSLayoutFlexItem>
              <div>
                <EGDSSpacing margin={{ inlineend: "three" }}>
                  <EGDSIcon id="playback-icon" name="search" title={formatText("wizard.hotel.pwa.expand.icon.title")} />
                </EGDSSpacing>
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <div>
                <EGDSText size={500} inline weight="medium" overflow="truncate" data-stid="content-location-title">
                  {formatText("wizard.hotel.pwa.expand.title")}
                </EGDSText>
                <EGDSText size={200}>{formatText("wizard.hotel.pwa.expand.subtitle")}</EGDSText>
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </div>
      </EGDSLink>
    </EGDSSpacing>
  );
}) as LocalizedComponentClass<ExpandoWizardProps>;

export default WizardHotelPWAExpando;

import { observable, action, computed, makeObservable } from "mobx";
import { Room } from "../typings";
import { locKeys } from "./l10n";
import { ILocalization } from "bernie-l10n";

export class RoomPickerModel {
  constructor({ adults, children, l10n }: { adults: string; children: string; l10n?: ILocalization }) {
    makeObservable(this, {
      rooms: observable,
      finalProps: computed,
      counts: computed,
      displaySummary: computed,
      roomCount: computed,
      disableAddRoom: computed,
      requestNineOrMoreRooms: computed,
      addRoom: action,
      removeRoom: action,
      changeAdultsForRoom: action,
      changeChildrenForRoom: action,
      changeChildAge: action,
      reset: action,
    });

    this.setupRooms(adults, children);
    this.l10n = l10n;
  }

  private static DEFAULT_CHILD_AGE = 0;

  private static MAX_ROOM_COUNT = 8;

  private static REQ_NINE_OR_MORE_ROOM = 3;

  private initAdults = "";

  private initChildren = "";

  private initialRoomCount = 1;

  private l10n?: ILocalization;

  public rooms: Room[] = [];

  /* room data structure:
      {
        adults: Number,
        children: [
          Number, Number ... // each number represents AGE
        ]
      }
   */

  get finalProps() {
    const adults = this.rooms
      .map((room) => room.adults)
      .filter((adult) => adult > 0)
      .join(",");

    const children = this.rooms
      .map((room, index) => this.convertRoomChildrenToString(index + 1, room.children))
      .filter((child) => child !== "")
      .join(",");

    return { adults, children };
  }

  get counts() {
    return this.rooms.reduce(
      (obj, room) => {
        obj.guests += room.adults + room.children.length;

        return obj;
      },
      { guests: 0, totalRooms: this.rooms.length }
    );
  }

  get displaySummary() {
    const { totalRooms, guests } = this.counts;

    return this.l10n && this.l10n.formatText(locKeys.roomsGuests, totalRooms, guests);
  }

  get roomCount() {
    return this.rooms.length;
  }

  get disableAddRoom() {
    if (this.rooms.length < this.initialRoomCount) {
      return false;
    }

    return this.rooms.length >= RoomPickerModel.MAX_ROOM_COUNT;
  }

  get requestNineOrMoreRooms() {
    return this.rooms.length >= RoomPickerModel.REQ_NINE_OR_MORE_ROOM;
  }

  public addRoom() {
    this.rooms.push({ adults: 1, children: [] });
  }

  public removeRoom(room: Room) {
    this.rooms = this.rooms.filter((rm) => rm !== room);
  }

  public changeAdultsForRoom(room: Room, count: number) {
    room.adults = count;
  }

  public changeChildrenForRoom(room: Room, count: number) {
    let diff = count - room.children.length;

    if (diff > 0) {
      for (diff; diff > 0; diff--) {
        room.children.push(RoomPickerModel.DEFAULT_CHILD_AGE);
      }
    } else if (diff < 0) {
      room.children.splice(room.children.length + diff, -diff);
    }
  }

  public changeChildAge(room: Room, index: number, age: number) {
    if (index >= 0 && index < room.children.length) {
      room.children[index] = age;
    }
  }

  public reset() {
    this.setupRooms(this.initAdults, this.initChildren);
  }

  private setupRooms(adultsText: string, childrenText: string) {
    const adults = adultsText ? adultsText.split(",") : [];
    const childrenRooms = this.convertChildrenTextToRooms(childrenText);
    const numberOfRooms = Math.max(adults.length, childrenRooms.length);
    const rooms = [];

    for (let i = 0; i < numberOfRooms; i++) {
      // note: parseInt() can return NaN
      const roomAdults = i < adults.length ? parseInt(adults[i], 10) : 0;
      const roomChildren = childrenRooms[i] ? childrenRooms[i] : [];

      rooms.push({
        adults: isNaN(roomAdults) ? 0 : roomAdults,
        children: roomChildren,
      });
    }

    this.initAdults = adultsText;
    this.initChildren = childrenText;
    this.rooms = rooms; //used to be .replace
    this.initialRoomCount = rooms.length;
  }

  private convertRoomChildrenToString(roomNumber: number, ages: number[]) {
    return ages.map((age) => `${roomNumber}_${age}`).join(",");
  }

  private convertChildrenTextToRooms(childrenText: string) {
    const items = childrenText?.split(",") || [];

    // TODO: Odd reducer here, technically it's a string array?
    return items.reduce((rooms: any[], item) => {
      // console.log('REDUCE CT:', rooms, item);
      const roomAge = item.split("_");
      if (roomAge.length !== 2) {
        return rooms; // invalid format sent down
      }

      let roomNumber = parseInt(roomAge[0], 10);
      const age = parseInt(roomAge[1], 10);
      if (isNaN(roomNumber) || isNaN(age)) {
        return rooms; // invalid data type for room_age
      }
      roomNumber -= 1; // turn to 0-indexed for rooms array

      if (rooms[roomNumber]) {
        rooms[roomNumber].push(age);
      } else {
        rooms[roomNumber] = [age];
      }

      return rooms;
    }, []);
  }
}

export const searchButtonCopyL10n = [
  "wizard.submit.label", // Original (0)
  "wizard.search.btn.copy.check-rates", // Variant 1
  "wizard.search.btn.copy.check-availability", // Variant 2
  "wizard.search.btn.copy.check-prices", // Variant 3
  "wizard.search.btn.copy.find-deals",
  "wizard.search.btn.copy.find-hotels",
  "wizard.search.btn.copy.find-lodging",
  "wizard.search.btn.copy.find-properties",
  "wizard.search.btn.copy.find-places-to-stay",
  "wizard.search.btn.copy.go",
  "wizard.search.btn.copy.get-deals",
  "wizard.search.btn.copy.get-hotels",
  "wizard.search.btn.copy.get-lodging",
  "wizard.search.btn.copy.get-properties",
  "wizard.search.btn.copy.get-places-to-stay",
  "wizard.search.btn.copy.show-me-deals",
  "wizard.search.btn.copy.show-me-hotels",
  "wizard.search.btn.copy.show-me-lodging",
  "wizard.search.btn.copy.show-me-properties",
  "wizard.search.btn.copy.show-me-places-to-stay",
  "wizard.search.btn.copy.inspire-me",
  "wizard.search.btn.copy.surprise-me",
  "wizard.search.btn.copy.delight-me", // Variant 23
];

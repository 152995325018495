import * as React from "react";
import { HSRFilters, HiddenSearchDataProps } from "../typings";

enum HSRFilterNames {
  AMENITIES = "amenities",
  TRAVELER_TYPE = "travelerType",
  SORT = "sort",
  PAYMENT_TYPE = "paymentType",
  PROPERTY_STYLE = "propertyStyle",
  MEAL_PLAN = "mealPlan",
}

/**
 * This is the data that gets sent to HSR via the wizard form. The dates are sent
 * by the UITK datepicket itself, which is why they are not set here. When
 * submitting, it will use these inputs to populate the query params of the URL
 */
export const HiddenSearchData = (props: HiddenSearchDataProps) => {
  const getHSRFilterValue = (key: HSRFilterNames, defaultValue?: string) => {
    return props.hsrFilters ? props.hsrFilters[key as keyof HSRFilters] : defaultValue;
  };
  const getbexApiFiltersValue = (key: HSRFilterNames, defaultValue?: string) => {
    return props.bexApiFilters ? props.bexApiFilters[key as keyof HSRFilters] : defaultValue;
  };

  const sort = getHSRFilterValue(HSRFilterNames.SORT, props.sort);
  const amenity = getbexApiFiltersValue(HSRFilterNames.AMENITIES);
  const paymentType = getHSRFilterValue(HSRFilterNames.PAYMENT_TYPE, props.hsrFilterCode);
  const propertyStyle = getHSRFilterValue(HSRFilterNames.PROPERTY_STYLE);
  const travelerType = getHSRFilterValue(HSRFilterNames.TRAVELER_TYPE);
  const mealPlan = getbexApiFiltersValue(HSRFilterNames.MEAL_PLAN);
  const openPlayBack = props.openPlayBack;

  return (
    <div>
      <input type="hidden" name="rfrr" value="hotel.search" />
      <input type="hidden" name="GOTO" value="HOTSEARCH" />
      <input type="hidden" name="SearchType" value="Place" />
      <input type="hidden" name="SearchArea" value="City" />
      <input type="hidden" name="lang" value={props.langId} />
      <input type="hidden" name="needUTF8Decode" value="true" />
      <input type="hidden" name="regionId" value={props.regionId} />
      <input type="hidden" name="selected" value={props.selected} />
      <input type="hidden" name="latLong" value={props.latLong} />
      <input type="hidden" name="children" value={props.children} />
      <input type="hidden" name="adults" value={props.adults} />
      {sort && <input type="hidden" name="sort" value={sort} />}

      {props.ttla && <input type="hidden" name="ttla" value={props.ttla} />}
      {props.expandForm && <input type="hidden" name="expanded" value={props.expandForm.toString()} />}

      {amenity && <input type="hidden" name="amenities" value={amenity} />}
      {paymentType && <input type="hidden" name="paymentType" value={paymentType} />}
      {travelerType && <input type="hidden" name="travelerType" value={travelerType} />}
      {mealPlan && <input type="hidden" name="mealPlan" value={mealPlan} />}
      {props.starRating && <input type="hidden" name="star" value={props.starRating} />}
      {props.propertyType && <input type="hidden" name="lodging" value={props.propertyType} />}
      {propertyStyle && <input type="hidden" name="propertyStyle" value={propertyStyle} />}
      {openPlayBack && <input type="hidden" name="openPlayBack" value={openPlayBack.toString()} />}
    </div>
  );
};

export const durationsDe = [
  {
    text: "kürzer als 6 Tage",
    value: "1-5",
  },
  {
    text: "6 - 9 Tage",
    value: "6-9",
  },
  {
    text: "10 - 15 Tage",
    value: "10-15",
  },
  {
    text: "länger als 15 Tage",
    value: "16-999",
  },
];

import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { WizardHotelPWA } from "./WizardHotelPWA";
import { WizardHotelPWAExpando } from "./views/WizardHotelPWAExpando";
import { WizardHotelPWAHotelscom } from "./views/WizardHotelPWAHotelscom";
import { WizardHotelPWAViewsProps } from "./typings";
import { withStores } from "stores";
import { useExperiment } from "@shared-ui/experiment-context";
import { PageType } from "typings/flexFramework/FlexDefinitions";
import { DEFAULT_BOOKING_WINDOW, OPTIMIZED_BOOKING_WINDOW } from "./utils/constants";

const DESTINATION_LANDING_PAGE_TYPES = new Set<PageType>([
  "Travel-Guide-Hotels",
  "Travel-Guide-Filter-Hotels",
  "Hotel-Destinations",
  "Travel-Guide-Accommodation",
  "Travel-Guide-VacationRentals",
  "Vacation-Rental-Destinations",
  "Hotel-Filter-Destinations",
  "Alternative-Accommodations",
]);

export const WizardHotelPWAViews = withStores(
  "analytics",
  "context",
  "cookies",
  "compositionStore",
  "wizardState",
  "flexModuleModelStore"
)((props: WizardHotelPWAViewsProps) => {
  const { context } = props;

  const isMobile = Boolean(context && context.deviceInformation && context.deviceInformation.mobile);

  const isDestinationLandingPage = context.searchContext.pageType
    ? DESTINATION_LANDING_PAGE_TYPES.has(context.searchContext.pageType)
    : false;

  const isBrandExpedia = context.site.brand === "expedia";

  const daysToAdd =
    isDestinationLandingPage && isMobile && isBrandExpedia ? OPTIMIZED_BOOKING_WINDOW : DEFAULT_BOOKING_WINDOW;

  const { exposure: bexSearchOptimizationForDesktop } = useExperiment(
    "Search_Form_UI_Optimisation_",
    isDestinationLandingPage && isBrandExpedia && !isMobile
  );

  const isCalendarKeptOpen = isBrandExpedia && isDestinationLandingPage;
  const isEarlyStickyTransition = isBrandExpedia && isDestinationLandingPage;
  const isBEXSearchOptimizationForDesktopVariant =
    bexSearchOptimizationForDesktop.bucket === 1 && isDestinationLandingPage && isBrandExpedia;
  if (!props.templateComponent) {
    return null;
  }

  const model = props.flexModuleModelStore.getModel(props.templateComponent.metadata.id);
  const history = useHistory();
  const location = useLocation();

  if (!model) {
    return null;
  }

  const { view, openPlayBackInSRP = false } = props.templateComponent.config;

  const viewProps = {
    ...props,
    history,
    location,
    openPlayBack: openPlayBackInSRP,
    daysToAdd,
    isCalendarKeptOpen,
    isEarlyStickyTransition,
    isBEXSearchOptimizationForDesktopVariant,
  };

  if (view === "expando") {
    return <WizardHotelPWAExpando {...viewProps} />;
  }

  if (view === "hotelscom") {
    return <WizardHotelPWAHotelscom {...viewProps} />;
  }

  return <WizardHotelPWA {...viewProps} />;
});

export default WizardHotelPWAViews;

import { FormFieldModel } from "../typings";
import { locKeys } from "../components/l10n";

interface SearchFormProps {
  [key: string]: FormFieldModel;
}

export const SearchForm: SearchFormProps = {
  HotelOnly: {
    formFieldName: "destination",
    formFieldLabel: locKeys.hotelDestinationFieldLabel,
    formFieldStid: "hotel-destination",
    typeaheadLob: "HOTELS",
    typeaheadPlaceholder: locKeys.typeaheadPlaceholder,
  },
};

export const cruisesLineDe = [
  {
    text: "1AVistaReisen",
    value: "161",
  },
  {
    text: "AIDA Cruises",
    value: "7",
  },
  {
    text: "Air-Maritime",
    value: "71",
  },
  {
    text: "Ambiente",
    value: "303",
  },
  {
    text: "Azamara",
    value: "203",
  },
  {
    text: "Carnival",
    value: "19",
  },
  {
    text: "Celebrity Cruises",
    value: "2",
  },
  {
    text: "Clipper",
    value: "129",
  },
  {
    text: "Club Med Cruises",
    value: "101",
  },
  {
    text: "Color Line",
    value: "145",
  },
  {
    text: "Costa Kreuzfahrten",
    value: "3",
  },
  {
    text: "CPTM - Aranui",
    value: "143",
  },
  {
    text: "Cruise West",
    value: "36",
  },
  {
    text: "Crystal",
    value: "23",
  },
  {
    text: "Cunard",
    value: "8",
  },
  {
    text: "Deilmann",
    value: "11",
  },
  {
    text: "Delphin",
    value: "13",
  },
  {
    text: "DERTUR Kreuzfahrten",
    value: "79",
  },
  {
    text: "DFDS",
    value: "199",
  },
  {
    text: "Disney Cruise Line",
    value: "123",
  },
  {
    text: "Du Ponant",
    value: "103",
  },
  {
    text: "e-hoi fly & cruise",
    value: "400",
  },
  {
    text: "e-hoi hin & weg",
    value: "312",
  },
  {
    text: "Fred. Olsen Cruises",
    value: "46",
  },
  {
    text: "FTI Cruises",
    value: "309",
  },
  {
    text: "H&H Touristik",
    value: "179",
  },
  {
    text: "Hansa Kreuzfahrten",
    value: "241",
  },
  {
    text: "Hapag-Lloyd Cruises",
    value: "9",
  },
  {
    text: "Hebridean",
    value: "128",
  },
  {
    text: "Holland America Line",
    value: "28",
  },
  {
    text: "Hurtigruten",
    value: "26",
  },
  {
    text: "I. D. Riva",
    value: "171",
  },
  {
    text: "Ikarus Tours",
    value: "255",
  },
  {
    text: "Ikon Reiseagentur",
    value: "198",
  },
  {
    text: "Insidian Seereisen",
    value: "150",
  },
  {
    text: "Lernidee",
    value: "326",
  },
  {
    text: "Louis Cruise Lines",
    value: "47",
  },
  {
    text: "MSC Kreuzfahrten",
    value: "5",
  },
  {
    text: "NCL",
    value: "17",
  },
  {
    text: "Oceania Cruises",
    value: "55",
  },
  {
    text: "Orient Lines",
    value: "41",
  },
  {
    text: "Orion Expedition Cruises",
    value: "113",
  },
  {
    text: "Passat Kreufzahrten",
    value: "317",
  },
  {
    text: "Phoenix",
    value: "10",
  },
  {
    text: "Plantours",
    value: "14",
  },
  {
    text: "Ponant",
    value: "83",
  },
  {
    text: "Princess",
    value: "18",
  },
  {
    text: "Pullmantur Cruise Gr",
    value: "151",
  },
  {
    text: "Regent",
    value: "24",
  },
  {
    text: "Royal Caribbean",
    value: "1",
  },
  {
    text: "Sea Cloud",
    value: "42",
  },
  {
    text: "Sea Travel",
    value: "31",
  },
  {
    text: "Seabourn",
    value: "27",
  },
  {
    text: "SeaDream Yacht Club",
    value: "56",
  },
  {
    text: "Se-Tours",
    value: "197",
  },
  {
    text: "Silversea",
    value: "25",
  },
  {
    text: "Star Clippers",
    value: "54",
  },
  {
    text: "Star Cruises",
    value: "107",
  },
  {
    text: "Transocean",
    value: "12",
  },
  {
    text: "Transocean",
    value: "299",
  },
  {
    text: "TUI Cruises",
    value: "229",
  },
  {
    text: "Turismo Skorpios",
    value: "152",
  },
  {
    text: "Vista Travel",
    value: "116",
  },
  {
    text: "VivaMare",
    value: "50",
  },
  {
    text: "Windstar Cruises",
    value: "22",
  },
  {
    text: "1A Vista Reisen",
    value: "161",
  },
  {
    text: "Air-Maritime",
    value: "71",
  },
  {
    text: "Anton Götten",
    value: "117",
  },
  {
    text: "aROSA",
    value: "51",
  },
  {
    text: "China Regal Cruises",
    value: "108",
  },
  {
    text: "Croisi Europe",
    value: "117",
  },
  {
    text: "DCS-Touristik",
    value: "72",
  },
  {
    text: "Deilmann",
    value: "11",
  },
  {
    text: "DERTOUR",
    value: "79",
  },
  {
    text: "e-hoi hin & weg",
    value: "312",
  },
  {
    text: "H&H TUR",
    value: "230",
  },
  {
    text: "Hansa Kreuzfahrten",
    value: "15",
  },
  {
    text: "KVS",
    value: "73",
  },
  {
    text: "Lernidee",
    value: "326",
  },
  {
    text: "Lüftner Cruises",
    value: "61",
  },
  {
    text: "Majestic",
    value: "48",
  },
  {
    text: "Nicko Cruises",
    value: "43",
  },
  {
    text: "Olympia-Reisen",
    value: "164",
  },
  {
    text: "Orient Express",
    value: "106",
  },
  {
    text: "Phoenix",
    value: "10",
  },
  {
    text: "Plantours",
    value: "14",
  },
  {
    text: "Radissimo",
    value: "200",
  },
  {
    text: "Sea Cloud",
    value: "42",
  },
  {
    text: "Select Voyages",
    value: "297",
  },
  {
    text: "SE-TOURS",
    value: "244",
  },
  {
    text: "Transocean",
    value: "12",
  },
  {
    text: "TUI",
    value: "170",
  },
  {
    text: "Victoria Cruises",
    value: "110",
  },
  {
    text: "Viking",
    value: "45",
  },
  {
    text: "Werner-Tours",
    value: "331",
  },
];

export const departurePortsAu = [
  {
    text: "Abu Dhabi",
    value: "3678",
  },
  {
    text: "Adelaide",
    value: "1029",
  },
  {
    text: "Akaroa",
    value: "6177",
  },
  {
    text: "All Departure Ports",
    value: "-99",
  },
  {
    text: "Amsterdam",
    value: "209",
  },
  {
    text: "Anchorage (Seward)",
    value: "71",
  },
  {
    text: "Anchorage (Whittier)",
    value: "2703",
  },
  {
    text: "Antwerp",
    value: "186",
  },
  {
    text: "Arles",
    value: "1174",
  },
  {
    text: "Athens",
    value: "4037",
  },
  {
    text: "Athens/Piraeus",
    value: "303",
  },
  {
    text: "Auckland",
    value: "381",
  },
  {
    text: "Avignon",
    value: "1169",
  },
  {
    text: "Balikpapan, Borneo",
    value: "4920",
  },
  {
    text: "Baltimore",
    value: "526",
  },
  {
    text: "Baltra Galapagos",
    value: "3026",
  },
  {
    text: "Bangkok",
    value: "561",
  },
  {
    text: "Barbados",
    value: "31",
  },
  {
    text: "Barcelona",
    value: "124",
  },
  {
    text: "Basel",
    value: "825",
  },
  {
    text: "Beijing",
    value: "560",
  },
  {
    text: "Benoa",
    value: "4260",
  },
  {
    text: "Benoa Bali",
    value: "371",
  },
  {
    text: "Bergen",
    value: "193",
  },
  {
    text: "Berlin",
    value: "4000",
  },
  {
    text: "Bilbao",
    value: "268",
  },
  {
    text: "Bombay",
    value: "500",
  },
  {
    text: "Bordeaux",
    value: "269",
  },
  {
    text: "Boston",
    value: "83",
  },
  {
    text: "Bremerhaven",
    value: "3110",
  },
  {
    text: "Bridgetown",
    value: "32",
  },
  {
    text: "Brisbane",
    value: "374",
  },
  {
    text: "Broome",
    value: "4440",
  },
  {
    text: "Brussels",
    value: "266",
  },
  {
    text: "Bucharest",
    value: "2752",
  },
  {
    text: "Budapest",
    value: "569",
  },
  {
    text: "Buenos Aires",
    value: "433",
  },
  {
    text: "Cairns",
    value: "5356",
  },
  {
    text: "Caldera",
    value: "648",
  },
  {
    text: "Callao (Lima)",
    value: "485",
  },
  {
    text: "Cape Liberty / Bayonne NJ",
    value: "3001",
  },
  {
    text: "Cape Town",
    value: "834",
  },
  {
    text: "Casa de Campo",
    value: "651",
  },
  {
    text: "Charleston SC",
    value: "279",
  },
  {
    text: "Chattanooga",
    value: "1441",
  },
  {
    text: "Chennai",
    value: "2803",
  },
  {
    text: "Civitavecchia/Rome",
    value: "216",
  },
  {
    text: "Clarkston WA",
    value: "2881",
  },
  {
    text: "Cologne",
    value: "1198",
  },
  {
    text: "Colombo",
    value: "1501",
  },
  {
    text: "Colon",
    value: "130",
  },
  {
    text: "Copenhagen",
    value: "169",
  },
  {
    text: "Dar Es Salaam",
    value: "3956",
  },
  {
    text: "Darwin",
    value: "4448",
  },
  {
    text: "Delhi",
    value: "4158",
  },
  {
    text: "Dijon",
    value: "1286",
  },
  {
    text: "Dover UK",
    value: "202",
  },
  {
    text: "Dubai U.A.E.",
    value: "677",
  },
  {
    text: "Dublin",
    value: "189",
  },
  {
    text: "Dubrovnik",
    value: "165",
  },
  {
    text: "Edinburgh",
    value: "267",
  },
  {
    text: "Fort Lauderdale",
    value: "10",
  },
  {
    text: "Fort-de-France",
    value: "50",
  },
  {
    text: "Fremantle",
    value: "1107",
  },
  {
    text: "Galapagos",
    value: "3889",
  },
  {
    text: "Galveston",
    value: "88",
  },
  {
    text: "Genoa",
    value: "306",
  },
  {
    text: "Glasgow",
    value: "4232",
  },
  {
    text: "Guayaquil",
    value: "590",
  },
  {
    text: "Haifa",
    value: "148",
  },
  {
    text: "Hamburg",
    value: "213",
  },
  {
    text: "Hanoi",
    value: "4255",
  },
  {
    text: "Ho Chi Minh City (My Tho)",
    value: "4713",
  },
  {
    text: "Ho Chi Minh City/Saigon",
    value: "1100",
  },
  {
    text: "Hong Kong",
    value: "538",
  },
  {
    text: "Honiara, Solomon Islands",
    value: "5563",
  },
  {
    text: "Honolulu",
    value: "78",
  },
  {
    text: "Ijmuiden",
    value: "5206",
  },
  {
    text: "Istanbul",
    value: "145",
  },
  {
    text: "Jacksonville",
    value: "1812",
  },
  {
    text: "Kanazawa",
    value: "2789",
  },
  {
    text: "Kangerlussuaq",
    value: "4678",
  },
  {
    text: "Kiel",
    value: "2710",
  },
  {
    text: "Kobe",
    value: "638",
  },
  {
    text: "Kolkata",
    value: "5864",
  },
  {
    text: "Koror Island",
    value: "4619",
  },
  {
    text: "Kota Kinabalu, Malaysia",
    value: "4931",
  },
  {
    text: "Leith",
    value: "2726",
  },
  {
    text: "Limassol",
    value: "155",
  },
  {
    text: "Lisbon",
    value: "156",
  },
  {
    text: "London",
    value: "566",
  },
  {
    text: "London (Dover)",
    value: "3897",
  },
  {
    text: "London (Southampton)",
    value: "3894",
  },
  {
    text: "London (Tilbury)",
    value: "4274",
  },
  {
    text: "London (Tower Bridge)",
    value: "3959",
  },
  {
    text: "Longyearbyen",
    value: "736",
  },
  {
    text: "Los Angeles",
    value: "4504",
  },
  {
    text: "Los Angeles (Long Beach)",
    value: "1721",
  },
  {
    text: "Los Angeles (San Pedro)",
    value: "89",
  },
  {
    text: "Louisville",
    value: "1467",
  },
  {
    text: "Loxton",
    value: "5200",
  },
  {
    text: "Lyon",
    value: "1183",
  },
  {
    text: "Madrid",
    value: "3286",
  },
  {
    text: "Mahe Island",
    value: "496",
  },
  {
    text: "Maizuru",
    value: "5289",
  },
  {
    text: "Male",
    value: "894",
  },
  {
    text: "Manaus",
    value: "1106",
  },
  {
    text: "Mannum, Sth Aust.",
    value: "4399",
  },
  {
    text: "Marseille",
    value: "159",
  },
  {
    text: "Melbourne",
    value: "375",
  },
  {
    text: "Memphis TN",
    value: "782",
  },
  {
    text: "Miami",
    value: "5",
  },
  {
    text: "Milford Sound",
    value: "4293",
  },
  {
    text: "Mobile",
    value: "616",
  },
  {
    text: "Mombasa",
    value: "449",
  },
  {
    text: "Monte Carlo",
    value: "135",
  },
  {
    text: "Montevideo",
    value: "434",
  },
  {
    text: "Montreal",
    value: "93",
  },
  {
    text: "Munich",
    value: "3813",
  },
  {
    text: "Nadi",
    value: "753",
  },
  {
    text: "Nashville",
    value: "1468",
  },
  {
    text: "New Orleans",
    value: "92",
  },
  {
    text: "New York",
    value: "9",
  },
  {
    text: "Nice",
    value: "227",
  },
  {
    text: "Nome",
    value: "2871",
  },
  {
    text: "Norfolk",
    value: "520",
  },
  {
    text: "Nuremberg",
    value: "1206",
  },
  {
    text: "Oranjestad (Aruba)",
    value: "39",
  },
  {
    text: "Osaka",
    value: "550",
  },
  {
    text: "Oslo",
    value: "177",
  },
  {
    text: "Otaru",
    value: "1086",
  },
  {
    text: "Papeete",
    value: "363",
  },
  {
    text: "Paris",
    value: "1130",
  },
  {
    text: "Passau",
    value: "1207",
  },
  {
    text: "Patna",
    value: "6101",
  },
  {
    text: "Perth",
    value: "1030",
  },
  {
    text: "Petropavlovsk",
    value: "3984",
  },
  {
    text: "Philipsburg St. Maarten",
    value: "27",
  },
  {
    text: "Phuket",
    value: "509",
  },
  {
    text: "Pointe-a-Pitre Guadeloupe",
    value: "17",
  },
  {
    text: "Port Canaveral",
    value: "41",
  },
  {
    text: "Port Denarau",
    value: "4601",
  },
  {
    text: "Port Louis (Mauritius)",
    value: "3093",
  },
  {
    text: "Port Vila",
    value: "4700",
  },
  {
    text: "Portland OR (Vancouver WA)",
    value: "5898",
  },
  {
    text: "Porto",
    value: "1238",
  },
  {
    text: "Portsmouth, UK",
    value: "4991",
  },
  {
    text: "Prague",
    value: "545",
  },
  {
    text: "Puerto Caldera",
    value: "292",
  },
  {
    text: "Punta Arenas Chile",
    value: "438",
  },
  {
    text: "Quebec",
    value: "102",
  },
  {
    text: "Quebec City",
    value: "2949",
  },
  {
    text: "Red Wing",
    value: "1453",
  },
  {
    text: "Reykjavik",
    value: "190",
  },
  {
    text: "Rio de Janeiro",
    value: "442",
  },
  {
    text: "Rotterdam",
    value: "181",
  },
  {
    text: "San Diego",
    value: "74",
  },
  {
    text: "San Francisco",
    value: "56",
  },
  {
    text: "San Juan",
    value: "25",
  },
  {
    text: "Santa Cruz de Tenerife",
    value: "210",
  },
  {
    text: "Santiago",
    value: "529",
  },
  {
    text: "Santiago/Valparaiso",
    value: "3303",
  },
  {
    text: "Santos",
    value: "446",
  },
  {
    text: "Savona",
    value: "467",
  },
  {
    text: "Seattle",
    value: "75",
  },
  {
    text: "Seward",
    value: "409",
  },
  {
    text: "Shanghai",
    value: "641",
  },
  {
    text: "Siem Reap",
    value: "4716",
  },
  {
    text: "Siem Reap (Tonle Sap Lake)",
    value: "4528",
  },
  {
    text: "Singapore",
    value: "382",
  },
  {
    text: "Southampton",
    value: "109",
  },
  {
    text: "Spokane, WA",
    value: "5612",
  },
  {
    text: "St Louis",
    value: "548",
  },
  {
    text: "St Paul",
    value: "549",
  },
  {
    text: "St. Denis (Reunion)",
    value: "4381",
  },
  {
    text: "St. Louis",
    value: "1451",
  },
  {
    text: "St. Paul",
    value: "1452",
  },
  {
    text: "St. Petersburg",
    value: "171",
  },
  {
    text: "Stockholm",
    value: "173",
  },
  {
    text: "Sydney",
    value: "383",
  },
  {
    text: "Tahiti",
    value: "360",
  },
  {
    text: "Tallinn",
    value: "170",
  },
  {
    text: "Tampa Bay",
    value: "84",
  },
  {
    text: "Tema",
    value: "885",
  },
  {
    text: "Tianjin",
    value: "4378",
  },
  {
    text: "Tokyo",
    value: "783",
  },
  {
    text: "Trieste",
    value: "2776",
  },
  {
    text: "Tromso",
    value: "248",
  },
  {
    text: "Ushuaia",
    value: "437",
  },
  {
    text: "Valencia",
    value: "368",
  },
  {
    text: "Valletta/Grand Harbour",
    value: "138",
  },
  {
    text: "Valparaiso",
    value: "440",
  },
  {
    text: "Vancouver",
    value: "70",
  },
  {
    text: "Venice",
    value: "146",
  },
  {
    text: "Vienna",
    value: "547",
  },
  {
    text: "Warnemunde/Berlin",
    value: "175",
  },
  {
    text: "Xiamen",
    value: "863",
  },
  {
    text: "Yangon (Rangoon)",
    value: "1663",
  },
  {
    text: "Yokohama",
    value: "637",
  },
  {
    text: "Zurich",
    value: "4092",
  },
];

import * as React from "react";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { EGDSPrimaryButton, EGDSButtonSize } from "@egds/react-core/button";

const ButtonTracked = FlexClickTracker(EGDSPrimaryButton);

export interface SubmitButtonProps {
  moduleName: string;
  submitButtonText: string;
  rfrr: string;
}

export const SubmitButton = React.memo<SubmitButtonProps>(({ moduleName, submitButtonText, rfrr }) => (
  <ButtonTracked
    tag="button"
    type="submit"
    rfrr={rfrr}
    linkName="Search button click event in the wizard"
    moduleName={moduleName}
    className="wizard-submit-btn"
    size={EGDSButtonSize.LARGE}
    isFullWidth
    data-testid="wizard-submit-button"
  >
    {submitButtonText}
  </ButtonTracked>
));

SubmitButton.displayName = "WizardHotelPWASubmitButton";

export const departurePortsDe = [
  {
    text: "Abu Dhabi (VAE)",
    value: "1582",
  },
  {
    text: "Ålesund (Norwegen)",
    value: "39",
  },
  {
    text: "Alexandria (Ägypten)",
    value: "201",
  },
  {
    text: "Amalfi (Italien)",
    value: "766",
  },
  {
    text: "Amsterdam (Niederlande)",
    value: "41",
  },
  {
    text: "Antalya (Türkei)",
    value: "204",
  },
  {
    text: "Antarktische Halbinsel (Antarktis)",
    value: "2876",
  },
  {
    text: "Antwerpen (Flandern, Belgien)",
    value: "490",
  },
  {
    text: "Århus (Dänemark)",
    value: "1387",
  },
  {
    text: "Assuan (Ägypten)",
    value: "1726",
  },
  {
    text: "Athen (Griechenland)",
    value: "1405",
  },
  {
    text: "Auckland (Neuseeland)",
    value: "496",
  },
  {
    text: "Avignon (Frankreich)",
    value: "1353",
  },
  {
    text: "Bahia de Nipe (Kuba)",
    value: "101",
  },
  {
    text: "Baltimore (Maryland, USA)",
    value: "104",
  },
  {
    text: "Bangkok (Thailand)",
    value: "263",
  },
  {
    text: "Barcelona (Spanien)",
    value: "205",
  },
  {
    text: "Bari (Italien)",
    value: "206",
  },
  {
    text: "Basel (Schweiz)",
    value: "1627",
  },
  {
    text: "Basseterre (St. Kitts, St. Kitts und Nevis)",
    value: "97",
  },
  {
    text: "Belgrad (Serbien)",
    value: "1333",
  },
  {
    text: "Bergen (Norwegen)",
    value: "44",
  },
  {
    text: "Bodø (Norwegen)",
    value: "508",
  },
  {
    text: "Bonn (Deutschland)",
    value: "2111",
  },
  {
    text: "Bordeaux (Frankreich)",
    value: "337",
  },
  {
    text: "Bremerhaven (Deutschland)",
    value: "45",
  },
  {
    text: "Bridgetown (Barbados)",
    value: "105",
  },
  {
    text: "Brussel / Bruxelles (Brüssel, Belgien)",
    value: "2635",
  },
  {
    text: "Budapest (Ungarn)",
    value: "1290",
  },
  {
    text: "Buenos Aires (Argentinien)",
    value: "17",
  },
  {
    text: "Busan (Südkorea)",
    value: "8",
  },
  {
    text: "Cádiz (Spanien)",
    value: "74",
  },
  {
    text: "Cagliari (Sardinien, Italien)",
    value: "208",
  },
  {
    text: "Cannes (Frankreich)",
    value: "511",
  },
  {
    text: "Capri (Italien)",
    value: "512",
  },
  {
    text: "Casablanca (Marokko)",
    value: "247",
  },
  {
    text: "Civitavecchia - Rom (Italien)",
    value: "209",
  },
  {
    text: "Colombo (Sri Lanka)",
    value: "275",
  },
  {
    text: "Corregidor (Philippinen)",
    value: "9",
  },
  {
    text: "Curacao (Curacao)",
    value: "681",
  },
  {
    text: "Cuxhaven (Deutschland)",
    value: "829",
  },
  {
    text: "Dendermonde (Belgien)",
    value: "3728",
  },
  {
    text: "Dover (England)",
    value: "76",
  },
  {
    text: "Dubai (VAE)",
    value: "281",
  },
  {
    text: "Dublin (Irland)",
    value: "77",
  },
  {
    text: "Dubrovnik (Kroatien)",
    value: "211",
  },
  {
    text: "Durban (Südafrika)",
    value: "283",
  },
  {
    text: "Düsseldorf (Deutschland)",
    value: "1622",
  },
  {
    text: "Fort Lauderdale (Florida, USA)",
    value: "120",
  },
  {
    text: "Fort-de-France (Martinique)",
    value: "119",
  },
  {
    text: "Frankfurt am Main (Deutschland)",
    value: "1310",
  },
  {
    text: "Fujairah (VAE)",
    value: "1443",
  },
  {
    text: "Gdansk / Danzig (Polen)",
    value: "1453",
  },
  {
    text: "Genua (Italien)",
    value: "213",
  },
  {
    text: "George Town (Grand Cayman, Kaimaninseln)",
    value: "118",
  },
  {
    text: "Goa (Indien)",
    value: "290",
  },
  {
    text: "Half Moon Cay (Bahamas)",
    value: "178",
  },
  {
    text: "Hamburg (Deutschland)",
    value: "55",
  },
  {
    text: "Hammerfest (Norwegen)",
    value: "56",
  },
  {
    text: "Havanna (Kuba)",
    value: "126",
  },
  {
    text: "Helsinki (Finnland)",
    value: "60",
  },
  {
    text: "Heraklion (Kreta, Griechenland)",
    value: "214",
  },
  {
    text: "Ho-Chi-Minh-Stadt (Vietnam)",
    value: "2016",
  },
  {
    text: "Hongkong (China)",
    value: "6",
  },
  {
    text: "Honningsvåg (Nordkap, Norwegen)",
    value: "419",
  },
  {
    text: "Istanbul (Türkei)",
    value: "215",
  },
  {
    text: "Kapstadt (Südafrika)",
    value: "294",
  },
  {
    text: "Karachi (Pakistan)",
    value: "297",
  },
  {
    text: "Karlsruhe (Deutschland)",
    value: "2161",
  },
  {
    text: "Katákolon - Olympia (Griechenland)",
    value: "217",
  },
  {
    text: "Kehl (Deutschland)",
    value: "3065",
  },
  {
    text: "Key West (Florida, USA)",
    value: "100",
  },
  {
    text: "Kiel - Holtenau (Deutschland)",
    value: "3553",
  },
  {
    text: "Kobe (Japan)",
    value: "288",
  },
  {
    text: "Koblenz (Deutschland)",
    value: "1570",
  },
  {
    text: "Kochi / Cochin (Kerala, Indien)",
    value: "274",
  },
  {
    text: "Köln (Deutschland)",
    value: "1588",
  },
  {
    text: "Kopenhagen (Dänemark)",
    value: "63",
  },
  {
    text: "Korcula (Kroatien)",
    value: "726",
  },
  {
    text: "Korfu (Griechenland)",
    value: "219",
  },
  {
    text: "Kusadasi - Ephesus (Türkei)",
    value: "221",
  },
  {
    text: "La Coruña (Spanien)",
    value: "348",
  },
  {
    text: "La Goulette - Tunis (Tunesien)",
    value: "471",
  },
  {
    text: "Le Havre (Frankreich)",
    value: "64",
  },
  {
    text: "Leith - Edinburgh (Schottland)",
    value: "349",
  },
  {
    text: "Linz (Österreich)",
    value: "1325",
  },
  {
    text: "Lissabon (Portugal)",
    value: "82",
  },
  {
    text: "Liverpool (England)",
    value: "744",
  },
  {
    text: "Livorno - Florenz/Pisa (Italien)",
    value: "475",
  },
  {
    text: "London (England)",
    value: "351",
  },
  {
    text: "Los Angeles (Kalifornien, USA)",
    value: "1086",
  },
  {
    text: "Luxor (Ägypten)",
    value: "779",
  },
  {
    text: "Lyon (Frankreich)",
    value: "1347",
  },
  {
    text: "Málaga (Spanien)",
    value: "84",
  },
  {
    text: "Manaus (Amazonas, Brasilien)",
    value: "438",
  },
  {
    text: "Marseille (Frankreich)",
    value: "228",
  },
  {
    text: "Maskat / Muscat (Oman)",
    value: "312",
  },
  {
    text: "Melk (Österreich)",
    value: "1294",
  },
  {
    text: "Miami (Florida, USA)",
    value: "152",
  },
  {
    text: "Moskau (Russland)",
    value: "1322",
  },
  {
    text: "Mykonos (Griechenland)",
    value: "230",
  },
  {
    text: "Nagasaki (Japan)",
    value: "11",
  },
  {
    text: "Neapel (Italien)",
    value: "231",
  },
  {
    text: "Nesebâr (Bulgarien)",
    value: "1089",
  },
  {
    text: "New York (New York, USA)",
    value: "163",
  },
  {
    text: "Newcastle upon Tyne (England)",
    value: "1568",
  },
  {
    text: "Nijmegen / Nimwegen (Niederlande)",
    value: "1619",
  },
  {
    text: "Nizza (Frankreich)",
    value: "481",
  },
  {
    text: "Osaka (Japan)",
    value: "303",
  },
  {
    text: "Palma (Mallorca, Balearische Inseln)",
    value: "233",
  },
  {
    text: "Paris (Frankreich)",
    value: "1556",
  },
  {
    text: "Passau (Deutschland)",
    value: "1288",
  },
  {
    text: "Patmos (Griechenland)",
    value: "707",
  },
  {
    text: "Peking (China)",
    value: "1",
  },
  {
    text: "Perth (Australien)",
    value: "3393",
  },
  {
    text: "Phuket (Thailand)",
    value: "571",
  },
  {
    text: "Piräus - Athen (Griechenland)",
    value: "235",
  },
  {
    text: "Portofino (Italien)",
    value: "659",
  },
  {
    text: "Prag (Tschechien)",
    value: "1644",
  },
  {
    text: "Princess Cays (Bahamas)",
    value: "683",
  },
  {
    text: "Puerto Limón (Costa Rica)",
    value: "135",
  },
  {
    text: "Puntarenas (Costa Rica)",
    value: "198",
  },
  {
    text: "Reykjavik (Island)",
    value: "30",
  },
  {
    text: "Rhodos (Griechenland)",
    value: "237",
  },
  {
    text: "Riga (Lettland)",
    value: "68",
  },
  {
    text: "Rio de Janeiro (Brasilien)",
    value: "31",
  },
  {
    text: "Rotterdam (Niederlande)",
    value: "888",
  },
  {
    text: "Rousse (Bulgarien)",
    value: "1336",
  },
  {
    text: "Rüdesheim (Deutschland)",
    value: "1362",
  },
  {
    text: "Safaga (Ägypten)",
    value: "318",
  },
  {
    text: "Saint-Tropez (Frankreich)",
    value: "668",
  },
  {
    text: "Salvador de Bahia (Brasilien)",
    value: "33",
  },
  {
    text: "Santorini (Griechenland)",
    value: "238",
  },
  {
    text: "Sao Paulo (Sao Paulo, Brasilien)",
    value: "35",
  },
  {
    text: "Savona (Italien)",
    value: "239",
  },
  {
    text: "Seattle (Washington, USA)",
    value: "387",
  },
  {
    text: "Shanghai (China)",
    value: "305",
  },
  {
    text: "Sharm El Sheikh (Ägypten)",
    value: "321",
  },
  {
    text: "Singapur (Singapur)",
    value: "403",
  },
  {
    text: "Sorrento (Italien)",
    value: "597",
  },
  {
    text: "Southampton (England)",
    value: "358",
  },
  {
    text: "Split (Kroatien)",
    value: "580",
  },
  {
    text: "St. John's (Antigua, Antigua und Barbuda)",
    value: "145",
  },
  {
    text: "St. Maarten (St. Maarten)",
    value: "599",
  },
  {
    text: "St. Petersburg (Russland)",
    value: "70",
  },
  {
    text: "Stockholm (Schweden)",
    value: "69",
  },
  {
    text: "Straßburg (Frankreich)",
    value: "1366",
  },
  {
    text: "Suezkanal (Ägypten)",
    value: "790",
  },
  {
    text: "Sydney (New South Wales, Australien)",
    value: "675",
  },
  {
    text: "Tanger (Marokko)",
    value: "462",
  },
  {
    text: "Taormina (Sizilien, Italien)",
    value: "669",
  },
  {
    text: "Tauranga (Neuseeland)",
    value: "601",
  },
  {
    text: "Tel Aviv (Israel)",
    value: "3261",
  },
  {
    text: "Teneriffa (Kanarische Inseln, Spanien)",
    value: "600",
  },
  {
    text: "Tianjin Xingang - Peking (China)",
    value: "680",
  },
  {
    text: "Tokio (Japan)",
    value: "308",
  },
  {
    text: "Tromsø (Norwegen)",
    value: "427",
  },
  {
    text: "Trondheim (Norwegen)",
    value: "433",
  },
  {
    text: "Valencia (Spanien)",
    value: "612",
  },
  {
    text: "Vancouver (British Columbia, Kanada)",
    value: "432",
  },
  {
    text: "Venedig (Italien)",
    value: "241",
  },
  {
    text: "Victoria (British Columbia, Kanada)",
    value: "402",
  },
  {
    text: "Vigo (Spanien)",
    value: "87",
  },
  {
    text: "Wellington (Neuseeland)",
    value: "333",
  },
  {
    text: "Wien (Österreich)",
    value: "1293",
  },
  {
    text: "Würzburg (Deutschland)",
    value: "1576",
  },
  {
    text: "Yokohama (Japan)",
    value: "310",
  },
];

export const dateRangesAu = [
  {
    monthKey: "januaryEscapeSentence",
    month: 1,
    years: {
      2023: "1X2023",
      2024: "1X2024",
      2025: "1X2025",
    },
  },
  {
    monthKey: "februaryEscapeSentence",
    month: 2,
    years: {
      2023: "2X2023",
      2024: "2X2024",
      2025: "2X2025",
    },
  },
  {
    monthKey: "marchEscapeSentence",
    month: 3,
    years: {
      2023: "3X2023",
      2024: "3X2024",
      2025: "3X2025",
    },
  },
  {
    monthKey: "aprilEscapeSentence",
    month: 4,
    years: {
      2023: "4X2023",
      2024: "4X2024",
      2025: "4X2025",
    },
  },
  {
    monthKey: "mayEscapeSentence",
    month: 5,
    years: {
      2023: "5X2023",
      2024: "5X2024",
      2025: "5X2025",
    },
  },
  {
    monthKey: "juneEscapeSentence",
    month: 6,
    years: {
      2023: "6X2023",
      2024: "6X2024",
      2025: "6X2025",
    },
  },
  {
    monthKey: "julyEscapeSentence",
    month: 7,
    years: {
      2023: "7X2023",
      2024: "7X2024",
      2025: "7X2025",
    },
  },
  {
    monthKey: "augustEscapeSentence",
    month: 8,
    years: {
      2023: "8X2023",
      2024: "8X2024",
      2025: "8X2025",
    },
  },
  {
    monthKey: "septemberEscapeSentence",
    month: 9,
    years: {
      2023: "9X2023",
      2024: "9X2024",
      2025: "9X2025",
    },
  },
  {
    monthKey: "octoberEscapeSentence",
    month: 10,
    years: {
      2022: "10X2022",
      2023: "10X2023",
      2024: "10X2024",
      2025: "10X2025",
    },
  },
  {
    monthKey: "novemberEscapeSentence",
    month: 11,
    years: {
      2022: "11X2022",
      2023: "11X2023",
      2024: "11X2024",
      2025: "11X2025",
    },
  },
  {
    monthKey: "decemberEscapeSentence",
    month: 12,
    years: {
      2022: "12X2022",
      2023: "12X2023",
      2024: "12X2024",
      2025: "12X2025",
    },
  },
];

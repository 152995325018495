export const destinationsAu = [
  {
    text: "Africa",
    value: "1",
  },
  {
    text: "Alaska",
    value: "2",
  },
  {
    text: "All Destinations",
    value: "-99",
  },
  {
    text: "Antarctic",
    value: "41",
  },
  {
    text: "Arctic",
    value: "136",
  },
  {
    text: "Asia",
    value: "37",
  },
  {
    text: "Asia River Cruise",
    value: "694",
  },
  {
    text: "Australia",
    value: "515",
  },
  {
    text: "Australia/New Zealand",
    value: "3",
  },
  {
    text: "Bahamas",
    value: "4",
  },
  {
    text: "Bermuda",
    value: "5",
  },
  {
    text: "Canada/New England",
    value: "6",
  },
  {
    text: "Caribbean",
    value: "19",
  },
  {
    text: "Caribbean, Eastern",
    value: "7",
  },
  {
    text: "Caribbean, Exotic",
    value: "21",
  },
  {
    text: "Caribbean, Southern",
    value: "8",
  },
  {
    text: "Caribbean, Western",
    value: "9",
  },
  {
    text: "Central America",
    value: "42",
  },
  {
    text: "China River Cruise",
    value: "696",
  },
  {
    text: "Europe",
    value: "54",
  },
  {
    text: "Europe River Cruise",
    value: "698",
  },
  {
    text: "Europe, Mediterranean",
    value: "20",
  },
  {
    text: "Europe, Northern",
    value: "10",
  },
  {
    text: "Europe, Western",
    value: "73",
  },
  {
    text: "Far East",
    value: "11",
  },
  {
    text: "Hawaii",
    value: "12",
  },
  {
    text: "Mexican Baja/Riviera",
    value: "14",
  },
  {
    text: "Mexico",
    value: "475",
  },
  {
    text: "Middle East",
    value: "59",
  },
  {
    text: "New England",
    value: "60",
  },
  {
    text: "New Zealand",
    value: "62",
  },
  {
    text: "Panama Canal",
    value: "16",
  },
  {
    text: "River Cruise, Americas",
    value: "38",
  },
  {
    text: "South America",
    value: "17",
  },
  {
    text: "South Pacific",
    value: "36",
  },
  {
    text: "Tahiti",
    value: "70",
  },
  {
    text: "Transatlantic",
    value: "18",
  },
  {
    text: "Transpacific",
    value: "39",
  },
  {
    text: "World Cruise",
    value: "74",
  },
];

import * as React from "react";
import { DestinationFieldProps } from "../typings";
import { hotelConfig } from "src/stores/wizard/config";
import { useLocalization } from "@shared-ui/localization-context";
import { useLazyTypeahead, TypeaheadFallbackInput } from "utils/hooks/useLazyTypeahead";

export const DestinationField = (props: DestinationFieldProps) => {
  const { typeaheadModule } = useLazyTypeahead();
  const { formatText } = useLocalization();

  if (!typeaheadModule) {
    return <TypeaheadFallbackInput label={formatText(props.formFieldModel.formFieldLabel)} />;
  }

  const { EGDSTypeahead, ESSAdapter } = typeaheadModule;

  return (
    <EGDSTypeahead
      adapter={
        new ESSAdapter({
          client: "HotelsLandingPage",
          dest: true,
          features: "postal_code|multiregion_parent|ta_hierarchy|google",
          guid: props.guid,
          inputId: props.formFieldModel.formFieldStid,
          lob: "HOTELS",
          locale: props.locale,
          maxResults: hotelConfig.location.destination.essAdapterConfig.maxResults,
          regionType: 2047,
          siteId: parseInt(props.siteId, 10),
          personalize: true,
          domain: "/",
        })
      }
      onClick={props.openDestinationTypeaheadTrack}
      onUpdate={props.updateDestinationField}
      hasClearButton={false}
      allowExactMatch
      icon="place"
      onMountCB={props.setDestinationRef}
      name={props.formFieldModel.formFieldName}
      stid={props.formFieldModel.formFieldStid}
      label={formatText(props.formFieldModel.formFieldLabel)}
      invalid={props.invalid}
      value={props.destination || ""}
      typeaheadPlaceholder={formatText(props.formFieldModel.typeaheadPlaceholder!)}
    />
  );
};

export default DestinationField;
